<template>
  <div>
    <v-snackbar v-model="snackbar" top right color="error">
      {{ textError }}
    </v-snackbar>
    <v-row>
      <v-col cols="12" lg="8">
        <div v-if="mobileVerificationErrorPopUp" class="error-message">
          {{ mobileVerificationError }}
          <button @click="verifyNow" class="verify-button">Verify Now</button>
        </div>
        <div v-if="identityVerificationSubmitedPopUp" class="error-message">
          {{ mobileVerificationError }}
        </div>
        <v-tabs deep-purple accent-4 class="elevation-2" v-model="tab">
          <v-tab> Profile</v-tab>
          <v-tab>Security</v-tab>
          <v-tab>Timezone</v-tab>
          <v-tab>Email Change History</v-tab>
          <v-tabs-items v-model="tab" class="pt-0">
            <v-divider class="pt-0 mt-0"></v-divider>
            <v-tab-item class="pt-5 pb-2">
              <v-container fluid class="px-md-4">
                <v-card flat>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <loading :active="isLoading" :loader="loader" />
                    <div>
                      <div style="font-size: small; text-align: right">
                        {{ profileData.emailChangedCount }}/3
                      </div>

                      <v-text-field
                        type=" email"
                        v-model.trim="profileData.email"
                        aria-label="E-mail"
                        :rules="emailRules"
                        placeholder="Email"
                        @input="inputed"
                        append-icon="email"
                        :disabled="
                          profileData.emailChangedCount < 3 ? false : true
                        "
                        @keyup="enterClicked(profileData.email, $event)"
                        :hint="dynamicError"
                        required
                        outlined
                        dense
                      >
                        <template slot="append">
                          <v-icon
                            v-if="showIconEmailError"
                            class="menu-icon-error"
                            >cancel</v-icon
                          >
                          <v-icon
                            v-if="showIconEmailVerified"
                            class="menu-icon-verify"
                            >check_circle</v-icon
                          >
                          <v-icon v-if="showIconEmail">email</v-icon>
                        </template>
                        >
                      </v-text-field>
                    </div>
                    <v-row>
                      <v-col cols="12" md="3" class="py-0">
                        <v-text-field
                          v-model="profileData.firstName"
                          :rules="firstNameRules"
                          :counter="maxCharacters"
                          label="First name"
                          maxLength="70"
                          append-icon="person"
                          outlined
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="3" class="py-0">
                        <v-text-field
                          v-model="profileData.lastName"
                          :rules="lastNameRules"
                          :counter="maxCharacters"
                          label="Last name"
                          maxLength="70"
                          append-icon="person"
                          outlined
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="3" class="py-0">
                        <v-select
                          :items="countryCodeOptions"
                          label="Country Code"
                          v-model="profileData.countryCode"
                          item-text="name"
                          item-value="code"
                          :disabled="
                            profileData.isPhoneVerified &&
                            profileData.isPhoneVerified === 'Y'
                          "
                          outlined
                          dense
                          required
                          :rules="countryCodeRules"
                        >
                          <template v-slot:item="data">
                            <div class="d-flex align-center">
                              <img
                                :src="getFlagURL(data.item.iso2)"
                                class="mr-2"
                                alt="flag"
                              />
                              <span>{{ data.item.name }}</span>
                              <span class="ml-auto">{{ data.item.code }}</span>
                            </div>
                          </template>
                          <template v-slot:selection="data">
                            <div class="d-flex align-center">
                              <img
                                :src="getFlagURL(data.item.iso2)"
                                class="mr-2"
                                alt="flag"
                              />
                              <span>{{ data.item.name }}</span>
                              <span class="ml-auto">{{ data.item.code }}</span>
                            </div>
                          </template>
                        </v-select>
                      </v-col>

                      <v-col cols="12" md="3" class="py-0">
                        <v-text-field
                          v-model="profileData.contactNumber"
                          :rules="numberRules"
                          counter="15"
                          placeholder="Contact Number"
                          type="number"
                          :disabled="
                            profileData.isPhoneVerified &&
                            profileData.isPhoneVerified === 'Y'
                          "
                          outlined
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4" class="py-0">
                        <v-text-field
                          v-model="profileData.companyName"
                          label="Company Name"
                          :rules="companyNameRules"
                          @keyup="checkValidCompanyName"
                          :counter="maxCharacters"
                          outlined
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4" class="py-0">
                        <v-text-field
                          v-model="profileData.taxNumber"
                          counter="18"
                          maxLength="18"
                          :rules="taxNumberRules"
                          @keyup="checkValidTaxNumber"
                          label="Tax number"
                          outlined
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4" class="py-0">
                        <v-text-field
                          v-model.trim="profileData.vatNumber"
                          label="Vat number"
                          :rules="vatNumberRules"
                          @keyup="checkValidVatNumber"
                          counter="18"
                          maxLength="18"
                          outlined
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" class="py-0">
                        <v-text-field
                          v-model="profileData.address1"
                          counter="82"
                          maxLength="82"
                          :rules="addressRules"
                          label="Address 1"
                          append-icon="home"
                          outlined
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="py-0">
                        <v-text-field
                          v-model="profileData.address2"
                          :rules="addressRules2"
                          counter="82"
                          maxLength="82"
                          label="Address 2"
                          append-icon="home_work"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="12" sm="6" class="py-0">
                        <v-autocomplete
                          :items="countryData"
                          :rules="countryRule"
                          v-model.trim="profileData.country"
                          @change="getStateList(profileData.country)"
                          label="Country"
                          append-icon="business"
                          outlined
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" class="py-0">
                        <v-select
                          v-if="showStateField"
                          :items="stateData"
                          v-model.trim="profileData.stateName"
                          @change="getProvinceCode(profileData.stateName)"
                          label="State/Region"
                          append-icon="location_on"
                          outlined
                          required
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" class="py-0">
                        <v-text-field
                          @keypress="checkCityCode($event)"
                          v-model="profileData.city"
                          label="City"
                          counter="15"
                          maxLength="15"
                          append-icon="location_city"
                          outlined
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="py-0">
                        <v-text-field
                          @keypress="checkValidPinCode($event)"
                          v-model="profileData.postalCode"
                          counter="15"
                          maxLength="15"
                          label="Pin Code"
                          append-icon="pin_drop"
                          outlined
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <div class="update-button">
                      <v-btn
                        :disabled="!valid"
                        style="align-item: center"
                        @click="updateProfile"
                        outlined
                        rounded
                        class="primary-btn"
                      >
                        Update Profile
                      </v-btn>
                    </div>
                  </v-form>
                </v-card>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <security />
            </v-tab-item>
            <v-tab-item class="pt-5 pb-2">
              <v-container fluid class="px-md-4">
                <v-card flat>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <loading :active="isLoading" :loader="loader" />
                    <v-select
                      :items="timeZoneCountries"
                      v-model.trim="selectedTimezone"
                      :rules="timeZoneRules"
                      label="Timezone"
                      outlined
                      required
                    ></v-select>
                    <div class="update-button">
                      <v-btn
                        :disabled="!valid"
                        @click="updateTimezone"
                        outlined
                        rounded
                        class="primary-btn align-center"
                      >
                        Update Timezone
                      </v-btn>
                    </div>
                  </v-form>
                </v-card>
              </v-container>
            </v-tab-item>
            <v-tab-item class="pt-5 pb-2">
              <v-container fluid class="px-md-4">
                <div>
                  <v-card elevation="1">
                    <v-data-table
                      :headers="headers"
                      :items="emailChangedHistory"
                      :options.sync="options"
                    >
                      <!-- <template v-slot:[`item.orderDate`]="{ item }">
                        {{ moment(item.orderDate).format("MMMM Do YYYY, h:mm:ss a") }}
                      </template> -->
                    </v-data-table>
                  </v-card>
                  <v-row>
                    <loading :active="isLoading" :loader="loader" />
                  </v-row>
                </div>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
      <v-col cols="12" lg="4">
        <v-card elevation="2" :loading="profileCardLoading" outlined>
          <v-card-text class="py-6 text-center">
            <!-- <v-img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtXJM32IOoubAOObSJmgTbsrgvvf2JlfYy4Q&usqp=CAU"
              width="100"
              class="mx-auto"
            >
            </v-img> -->
            <!-- <v-list-item-avatar> -->
            <v-avatar v-if="checkImg" size="100">
              <img :src="userData.profileImageData" alt="user" width="100" />
            </v-avatar>
            <img v-else :src="dummyImg" alt="dummyuser" width="100" />
            <!-- </v-list-item-avatar> -->
            <h4 class="title-text font-weight-bold pt-5">
              {{ profileData.firstName }} {{ profileData.lastName }}
            </h4>
            <p class="text--secondary pt-3">
              <v-icon small color="grey lighten-1">mail</v-icon>
              {{ profileData.email }}
            </p>
            <p class="subtitle-text pt-5">CONTACT INFORMATION</p>
            <h4 class="title-text font-weight-light">
              <v-icon>call</v-icon>
              {{ profileData.contactNumber }}
            </h4>

            <p class="subtitle-text pt-6">ADDRESS INFORMATION</p>
            <h4 class="title-text font-weight-light pt-3">
              <v-icon>location_city</v-icon>
              {{ profileData.city }}
            </h4>
            <h4 class="title-text font-weight-light pt-5">
              <v-icon>pin_drop</v-icon>
              {{ profileData.postalCode }}
            </h4>
            <h4 class="title-text font-weight-light pt-5">
              <v-icon>location_on</v-icon>
              {{ profileData.stateName ? profileData.stateName : "-" }}
            </h4>
            <h4 class="title-text font-weight-light pt-5">
              <v-icon>home</v-icon>
              {{ profileData.address1 }}
            </h4>
            <h4
              class="title-text font-weight-light pt-5"
              v-if="profileData.address2"
            >
              <v-icon>home_work</v-icon>
              {{ profileData.address2 ? profileData.address2 : "-" }}
            </h4>
            <h4 class="title-text font-weight-light pt-5">
              <v-icon>business</v-icon>
              {{ profileData.country ? profileData.country : "-" }}
            </h4>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showEmailChangeDialog" max-width="400px" persistent>
      <v-card flat class="Card-style pa-2">
        <v-container fluid>
          <v-layout justify-center>
            <v-flex xs12 sm12>
              <v-flex xs12>
                <p>
                  <b>Are you sure that you wish to change the email ?</b>
                </p>
                <p>
                  <b>Note:</b>
                  Changing email address will also change your Login email
                  address. You will have to use the new email address to Login,
                  once change is confirmed.
                </p>
              </v-flex>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="cancelEmailChangeDialogPopUp"
                >
                  Cancel
                </v-btn>
                <v-btn @click="createEmailVerification" depressed
                  >Proceed</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isMainDialogVisible" max-width="900px">
      <v-card class="rounded-card">
        <v-card-title
          class="headline d-flex justify-space-between align-center"
        >
          <span>Your account is currently pending verification</span>
          <v-btn icon @click="closeMainDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p class="modal-font-weight">
            In order to activate account, please confirm phone number by
            following step 1 of the account verification process.
          </p>
          <p class="modal-font-weight">
            Account verification is required for security and compliance. Your
            account information will be handled according to our
            <a
              href="https://www.didnumberprovider.com/terms-conditions/"
              target="_blank"
              >privacy policy</a
            >.
          </p>
          <v-row>
            <v-col cols="12" md="6">
              <div class="step">
                <h3 style="color: black; font-weight: 500px !important">
                  <span class="step-number">1.</span> Address and phone number
                  verification
                </h3>
                <p class="modal-font-weight" style="margin-left: 22px">
                  You will need to provide the primary account holder details,
                  the service address, and confirm your phone number with an SMS
                  code.
                </p>
                <div class="d-flex justify-end">
                  <v-btn
                    @click="showAddressPhoneDialog"
                    color="primary"
                    class="ml-4"
                    :disabled="phoneNumberVerified"
                  >
                    Proceed
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="step">
                <h3 style="color: black; font-weight: 500px !important">
                  <span class="step-number">2.</span> Identity verification
                </h3>
                <p class="modal-font-weight" style="margin-left: 22px">
                  A government-issued ID is required to prove your identity. You
                  may need a smartphone or webcam during the process.
                </p>
                <div class="d-flex justify-end">
                  <v-btn
                    @click="showIdentityDialog"
                    color="primary"
                    class="ml-4"
                    :disabled="isIdentityVerified == false"
                  >
                    Proceed
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- <v-card-actions class="d-flex justify-end">
          <v-btn @click="closeMainDialog">Close</v-btn>
        </v-card-actions>-->
      </v-card>
    </v-dialog>

    <!-- Address and Phone Number Verification Dialog -->
    <v-dialog v-model="isAddressPhoneDialogVisible" max-width="900px">
      <v-card class="rounded-card">
        <v-card-title
          class="headline d-flex justify-space-between align-center"
        >
          <span>Your account is currently pending verification</span>
          <v-btn icon @click="closeAddressPhoneDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <h3 class="section-title modal-font-weight">
              1. Request a verification code
            </h3>
            <p class="section-description" style="margin-left: 14px">
              Please make sure the phone number is correct and request a
              verification code.
            </p>
            <v-row>
              <v-col cols="4" style="margin-top: 18px">
                <v-select
                  :items="countryCodeOptions"
                  label="Country Code"
                  v-model="profileData.countryCode"
                  item-text="name"
                  item-value="code"
                  dense
                  required
                  :rules="countryCodeRules"
                >
                  <template v-slot:item="data">
                    <div class="d-flex align-center">
                      <img
                        :src="getFlagURL(data.item.iso2)"
                        class="mr-2"
                        alt="flag"
                      />
                      <span>{{ data.item.name }}</span>
                      <span class="ml-auto">{{ data.item.code }}</span>
                    </div>
                  </template>
                  <template v-slot:selection="data">
                    <div class="d-flex align-center">
                      <img
                        :src="getFlagURL(data.item.iso2)"
                        class="mr-2"
                        alt="flag"
                      />
                      <span>{{ data.item.name }}</span>
                      <span class="ml-auto">{{ data.item.code }}</span>
                    </div>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="profileData.contactNumber"
                  :rules="numberRules"
                  counter="15"
                  placeholder="Contact Number"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn @click="sendVerificationOtp" color="primary" block>
                  Request a verification code
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3 class="section-title modal-font-weight">
                  2. Enter the verification code
                </h3>
                <p class="section-description" style="margin-left: 17px">
                  Enter the verification code received in the SMS and press the
                  'Verify phone number' button to continue.
                </p>
                <v-text-field
                  v-model="verificationCode"
                  counter="6"
                  maxLength="6"
                  placeholder="Verification code"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn
                  @click="verifyVerificationOtp"
                  color="primary"
                  block
                  :disabled="verificationCode.length !== 6"
                >
                  Verify phone number
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-row class="mt-4">
            <v-col cols="12">
              <p class="verification-help">
                Did not receive SMS? Kindly email
                <a href="mailto:customer.support@didnumberprovider.com"
                  >customer.support@didnumberprovider.com</a
                >
              </p>
            </v-col>
            <!-- <v-col cols="4" class="d-flex justify-end">
              <v-btn @click="closeAddressPhoneDialog" color="primary" block>
                Close
              </v-btn>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isIdentityDialogVisible" max-width="1100px">
      <!-- <div v-for="(country, i) in countryNameList" :key="i"> -->
      <v-card class="rounded-card">
        <v-card-title
          class="headline d-flex justify-space-between align-center"
        >
          <span>Your account is currently pending verification</span>
          <v-btn icon @click="isIdentityDialogVisible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>
        <!-- alerts message when aprroved document -->
        <v-col cols="12" class="pb-0">
          <p>
            Your account will be reviewed by our team member within the next
            business day. Please note that we may request additional information
            by email or over by the phone.
          </p>
          <!-- personal form -->
          <v-card-subtitle class="pl-1 pb-3"
            ><b>Personal Information:-</b>
          </v-card-subtitle>
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="4" class="py-0">
                <v-text-field
                  label="First name"
                  v-model="userDocumentInfo.firstName"
                  color="primary"
                  :rules="firstNameRules"
                  :counter="maxCharacters"
                  outlined
                  dense
                  :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-text-field
                  label="Middle name"
                  :rules="middleNameRules"
                  v-model="userDocumentInfo.middleName"
                  :counter="maxCharacters"
                  color="primary"
                  outlined
                  dense
                  :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-text-field
                  label="Last name"
                  v-model="userDocumentInfo.lastName"
                  :counter="maxCharacters"
                  color="primary"
                  :rules="lastNameRules"
                  outlined
                  dense
                  :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                >
                </v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="4" class="py-0">
                  <v-select
                    :items="countryCodeOptions"
                    label="Country"
                    v-model="userDocumentInfo.countryCode"
                    item-text="name"
                    item-value="code"
                    outlined
                    dense
                  ></v-select>
                </v-col> -->
              <v-col cols="12" sm="4" class="py-0">
                <v-select
                  :items="countryCodeOptions"
                  label="Country Code"
                  v-model="userDocumentInfo.countryCode"
                  item-text="name"
                  item-value="code"
                  outlined
                  dense
                  :rules="countryCodeRules"
                  :disabled="
                    userDocumentInfo.statusId == 'DOC_APPROVED' &&
                    !showCountryCode
                  "
                >
                  <template v-slot:item="data">
                    <div class="d-flex align-center">
                      <img
                        :src="getFlagURL(data.item.iso2)"
                        class="mr-2"
                        alt="flag"
                      />
                      <span>{{ data.item.name }}</span>
                      <span class="ml-auto">{{ data.item.code }}</span>
                    </div>
                  </template>
                  <template v-slot:selection="data">
                    <div class="d-flex align-center">
                      <img
                        :src="getFlagURL(data.item.iso2)"
                        class="mr-2"
                        alt="flag"
                      />
                      <span>{{ data.item.name }}</span>
                      <span class="ml-auto">{{ data.item.code }}</span>
                    </div>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" sm="4" class="py-0">
                <v-text-field
                  type="number"
                  label="Phone Number"
                  v-model="userDocumentInfo.contactNumber"
                  color="primary"
                  :counter="15"
                  :rules="mobileNumberRules"
                  outlined
                  dense
                  :disabled="
                    userDocumentInfo.statusId == 'DOC_APPROVED' &&
                    !showPhoneNumber
                  "
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-menu
                  ref="dateMenu"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Date Of Birth"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      readonly
                      dense
                      :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                      v-model="userDocumentInfo.dateOfBirth"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="userDocumentInfo.dateOfBirth"
                    no-title
                    :max="maxDate"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-text-field
                  label="Personal Tax ID "
                  :rules="taxIdRules"
                  v-model="userDocumentInfo.personalTaxId"
                  color="primary"
                  counter="18"
                  outlined
                  dense
                  :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-text-field
                  label="Address"
                  v-model="userDocumentInfo.address"
                  color="primary"
                  :rules="addressRules"
                  counter="82"
                  outlined
                  dense
                  :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-text-field
                  label="City"
                  v-model="userDocumentInfo.city"
                  :rules="cityRules"
                  counter="15"
                  color="primary"
                  outlined
                  dense
                  :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-autocomplete
                  :items="countryData"
                  :rules="countryRule"
                  v-model.trim="userDocumentInfo.country"
                  @change="getStateList(profileData.country)"
                  label="Country"
                  append-icon="business"
                  color="primary"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-text-field
                  label="ZIP Code or Postal Code"
                  v-model="userDocumentInfo.postalCode"
                  color="primary"
                  :rules="postalCodeRules"
                  counter="9"
                  outlined
                  dense
                  :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-text-field
                  label="Country of Birth"
                  v-model="userDocumentInfo.countryOfBirth"
                  color="primary"
                  :rules="countryOfBirthRules"
                  counter="25"
                  outlined
                  dense
                  :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <!-- upload files -->
            <v-divider class="mb-2"></v-divider>
            <v-card-subtitle class="pl-1 pb-3"
              ><b>Upload Documents:- </b> File extension should be .jpeg, .jpg,
              .pdf or .png.
            </v-card-subtitle>
            <v-row>
              <v-col cols="12" sm="6">
                <div class="document-upload">
                  <p>
                    <b
                      >Photo of yourself (selfie) with a government-issued ID</b
                    >
                  </p>
                  <ul>
                    <li>ID is clearly visible and readable</li>
                    <li>Look straight at the camera</li>
                    <li>No sunglasses, a hat, or a headband</li>
                  </ul>
                  <v-file-input
                    class="custom-file-input"
                    label="Drag and drop your files here or click to browse"
                    prepend-icon="mdi-file-document"
                    v-model="userDocumentInfo.document1"
                    @change="
                      uploadFiles('OTH_VERY_ID_PRF', userDocumentInfo.document1)
                    "
                    show-size
                    truncate-length="15"
                    outlined
                    dense
                    clearable
                  ></v-file-input>
                </div>
              </v-col>

              <v-col cols="12" sm="6">
                <div class="document-upload">
                  <p>
                    <b
                      >Photo of yourself (selfie) with our website in the
                      background</b
                    >
                  </p>
                  <ul>
                    <li>
                      Website can be either a main one or a customer portal
                    </li>
                    <li>Website must be sufficiently distinguishable</li>
                    <li>No sunglasses, a hat, or a headband</li>
                  </ul>
                  <v-file-input
                    class="custom-file-input"
                    label="Drag and drop your files here or click to browse"
                    prepend-icon="mdi-file-document"
                    v-model="userDocumentInfo.document2"
                    @change="uploadFiles('SELFIE', userDocumentInfo.document2)"
                    show-size
                    truncate-length="15"
                    outlined
                    dense
                    clearable
                  ></v-file-input>
                </div>
              </v-col>
            </v-row>
            <v-checkbox
              v-model="agreedToTerms"
              :rules="[
                (v) => !!v || 'You must agree to the terms and conditions',
              ]"
              label="Agree to service terms and conditions"
            ></v-checkbox>
          </v-form>

          <v-card-actions class="d-flex justify-end align-center">
            <v-btn
              @click="submitUserForm"
              color="primary"
              :disabled="!canSubmit"
              :loading="submitLoader"
              class="mr-2"
              >Submit</v-btn
            >
            <v-btn @click="isIdentityDialogVisible = false">Close</v-btn>
          </v-card-actions>

          <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="isIdentityDialogVisible = false"
              depressed
              color="error"
              elevation="0"
              class="mt-2"
              >Cancel</v-btn
            >
            <v-btn
              depressed
              color="primary"
              class="mt-2"
              :disabled="!isDisabled || !isFormValid"
              :loading="submitLoader"
              @click="submitUserForm(userDocumentInfo.documentInfoId)"
              elevation="0"
            >
              Submit
            </v-btn> 
          </v-card-actions> -->
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import shoppingCartAPI from "@/services/shoppingCartAPI.js";
import countryStateAPI from "@/services/countryStateAPI.js";
import profileAPI from "@/services/profileAPI.js";
import Security from "./security.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import dummyImg from "../../assets/images/dummyProfile.jpeg";
import timeZoneCountriesList from "./timeZoneCountriesList";
import {
  countryCodeOptions,
  getFlagURL,
} from "../ShoppingCart/countryCodeOptionsList";

export default {
  components: {
    security: Security,
    Loading,
  },
  data: () => ({
    isFormValid: true,
    isDisabled: true,
    submitLoader: false,

    userDocumentInfo: {},
    countryCodeOptions: [],
    userData: {},
    dummyImg: dummyImg,
    checkImg: "",
    valid: true,
    phoneNumberVerified: true,
    isIdentityVerified: false,
    headers: [
      {
        text: "Email Address",
        value: "emailAddress",
        align: "center",
        filterable: true,
      },
      {
        text: "Disabled By",
        value: "disabledBy",
        align: "center",
        sortable: true,
      },
      {
        text: "Disabled Date Time",
        value: "disabledDateTime",
        align: "center",
        sortable: true,
      },
      {
        text: "Enabled",
        value: "enabled",
        align: "center",
        sortable: true,
      },
    ],
    emailChangedHistory: [],
    isLoading: false,
    profileCardLoading: false,
    //fullPage: false,
    showEmailChangeDialog: false,
    snackbar: false,
    textError: "",
    mobileVerificationError: "",
    mobileVerificationErrorPopUp: false,
    identityVerificationSubmitedPopUp: false,
    showIconEmail: true,
    showIconEmailVerified: false,
    showIconEmailError: false,
    loader: "bars",
    maxCharacters: 70,
    showOtpInput: false,

    firstNameRules: [
      (v) => !!v || "Field is reuired",
      (v) => (v && v.length <= 70) || "Maximum 70 characters allowed",
      (v) => (/[^\w\s]/.test(v) ? "Special characters are not allowed" : true),
    ],
    //^[ A-Za-z0-9_-.\s]*$/i
    lastNameRules: [
      (v) => !!v || "Field is reuired",
      (v) => (v && v.length <= 70) || "Maximum 70 characters allowed",
      (v) => (/[^\w\s]/.test(v) ? "Special characters are not allowed" : true),
    ],
    emailRules: [
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "this value should be a valid email",
    ],
    firstName: "",
    lastName: "",

    // address1: "",
    addressRules: [
      (v) => !!v || "Field is reuired",
      (v) => (v && v.length <= 82) || "Maximum 82 characters allowed",

      (v) => (/[^\w\s]/.test(v) ? "Special characters are not allowed" : true),
    ],

    addressRules2: [
      (v) => !!v || "Field is reuired",
      (v) => (v && v.length <= 82) || "Maximum 82 characters allowed",
      (v) => (/[^\w\s]/.test(v) ? "Special characters are not allowed" : true),
    ],

    numberRules: [
      // (v) =>
      //   (v && v.length <= 16 && /^[a-zA-Z0-9\-\s- ]{0,10}$/.test(v)) ||
      //   "please add a contact number - digits only",
      (v) =>
        (v && v.length >= 8) || "This field must have atleast 8 characters",
      (v) => (v && v.length <= 15) || "This field exceeds maximum 15 allowed",
    ],
    postalCodeRule: [
      (v) =>
        /^[a-zA-Z0-9\-\s- ]{0,9}$/.test(v) ||
        "pin code can only contain letters, numbers, spaces and hyphens",
    ],
    countryCodeRules: [
      (v) => !!v || "Field is required", // Check if the field is filled
      // (v) => v.length > 0 || "Please select a country code"  // Ensures a country code is selected
    ],
    timeZoneRules: [(v) => !!v || "this field is required"],
    countryRule: [
      (v) => !!v || "this value is required",
      (v) =>
        !!v ||
        /^[/_A-Za-z_ ()'-]+$/.test(v) ||
        "only alphabet, slash(/), apostrophe(), hyphens(-) and space are allowed in name field",
    ],
    stateRule: [
      (v) => !!v || "this value is required",
      (v) =>
        !!v ||
        /^[/_A-Za-z_ ()'-]+$/.test(v) ||
        "only alphabet, slash(/), apostrophe(), hyphens(-) and space are allowed in name field",
    ],
    cityRule: [
      (v) => !!v || "this value is required",
      (v) => (v && v.length <= 15) || "Maximum 15 characters allowed",
      (v) => (/[^\w\s]/.test(v) ? "Special characters are not allowed" : true),
    ],
    middleNameRules: [
      (v) => (v && v.length >= 70 ? "Maximum 18 characters allowed" : true),
      (v) => (/[^\w\s]/.test(v) ? "Special characters are not allowed" : true),
    ],
    taxIdRules: [
      (v) => (v && v.length >= 18 ? "Maximum 18 characters allowed" : true),
      (v) => (/[^\w\s]/.test(v) ? "Special characters are not allowed" : true),
    ],
    countryRules: [
      (v) => !!v || "this value is required",
      (v) => (/[^\w\s]/.test(v) ? "Special characters are not allowed" : true),
    ],
    postalCodeRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length <= 9) || "Maximum 9 characters allowed",
      (v) =>
        /^[a-zA-Z0-9\-\s- ]{0,9}$/.test(v) ||
        "pin code can only contain letters, numbers, spaces and hyphens",
    ],
    countryOfBirthRules: [
      (v) => !!v || "Field is required", // Check if the field is filled
      (v) => (v && v.length <= 25) || "Maximum 25 characters allowed", // Maximum length check
      (v) => /^[^\d]+$/.test(v) || "Numeric values are not allowed", // No numeric values allowed
    ],
    businessNameRules: [
      (v) => !!v || "Field is required",
      (v) => (/[^\w\s]/.test(v) ? "Special characters are not allowed" : true),
    ],
    vatIdRules: [
      (v) => (/[^\w\s]/.test(v) ? "Special characters are not allowed" : true),
    ],
    companyRegistrationRules: [
      (v) => (/[^\w\s]/.test(v) ? "Special characters are not allowed" : true),
    ],
    incorportationCountryRules: [
      (v) => (/[^\w\s]/.test(v) ? "Special characters are not allowed" : true),
    ],
    cityRules: [
      (v) => !!v || "this value is required",
      (v) => (v && v.length <= 15) || "Maximum 15 characters allowed",
      (v) => (/[^\w\s]/.test(v) ? "Special characters are not allowed" : true),
    ],
    mobileNumberRules: [
      (v) => !!v || "Field is required", // Check if the field is filled
      (v) => (v && v.length >= 8) || "Minimum 8 digits required", // Minimum length check
      (v) => (v && v.length <= 15) || "Maximum 15 digits allowed", // Maximum length check
      (v) => /^\d+$/.test(v) || "Only numeric values are allowed", // Only numeric check
    ],
    maxDate: "",
    dateMenu: "",
    radioGroup: "PERSONAL",
    outList: [],
    companyNameRules: [],
    vatNumberRules: [],
    taxNumberRules: [],
    countryData: [],
    stateData: [],
    geoIDSelected: "ARG",
    tab: null,
    document: {},
    profileData: {},
    countryGeoId: "",
    selectedIdentity: "",
    userDetail: {},
    showStateField: Boolean,
    getPassword: null,
    timeZoneCountries: [],
    selectedTimezone: "",
    getTimezoneData: {},
    isMainDialogVisible: false,
    isAddressPhoneDialogVisible: false,
    phoneNumber: "",
    verificationCode: "",
    isAddressPhoneVerified: false,
    isIdentityDialogVisible: false,
    agreedToTerms: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    uploadedFiles: [],
  }),
  mounted() {
    this.countryCodeOptions = countryCodeOptions;
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    var getPassword = localStorage.getItem("saveAuth");
    this.getPassword = window.atob(getPassword);
    const timeZoneCountries = timeZoneCountriesList();
    this.timeZoneCountries = timeZoneCountries;
    this.getCountryList();
    this.getTimezoeData();
    this.getEmailChangeHistory();
    this.isLoading = true;
    setTimeout(() => {
      this.getUserDetail();
    }, 2000);
  },
  computed: {
    isPhoneValid() {
      console.log("this.dadada", this.profileData);
      return this.profileData.countryCode && this.profileData.contactNumber;
    },

    canSubmit() {
      // Check if the form is valid
      const isFormValid = this.isFormValid;

      // Check if all required documents are uploaded
      const areDocumentsUploaded =
        !!this.userDocumentInfo.document1 && !!this.userDocumentInfo.document2;

      // Check if terms are agreed to
      const termsAgreed = this.agreedToTerms;

      return isFormValid && areDocumentsUploaded && termsAgreed;
    },

    isOtpValid() {
      console.log("this.dadada", this.profileData);
      return this.profileData.code;
    },
  },
  methods: {
    async uploadFiles(fileName, file) {
      if (!file) return;

      // Validate the file type
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      if (!allowedTypes.includes(file.type)) {
        alert("Please select an image in JPEG, JPG, PDF or PNG format.");
      }

      // Convert the file to a base64 string using a canvas for image processing
      const base64File = await this.processFileWithCanvas(file);

      // Get the MIME type of the file
      const mimeType = file.type;

      // Create the upload object
      const uploadObject = {
        uploadedFile: base64File,
        fileName: fileName,
        mimeTypeId: mimeType,
        documentType: "PERSONAL",
        geoId: this.geoIDSelected,
      };

      // Push the object to the array
      this.uploadedFiles.push(uploadObject);
    },

    processFileWithCanvas(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          if (file.type != "application/pdf") {
            var img = new Image();

            img.src = event.target.result;

            img.onload = () => {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");

              // Set canvas dimensions
              const MAX_WIDTH = 1000;
              const MAX_HEIGHT = 800;
              let width = img.width;
              let height = img.height;

              if (width > height) {
                if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
                }
              } else {
                if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
                }
              }

              canvas.width = width;
              canvas.height = height;

              // Draw the image on the canvas
              ctx.drawImage(img, 0, 0, width, height);

              // Get the compressed data URL from the canvas
              const compressedDataUrl = canvas.toDataURL("image/jpeg", 1.0);
              resolve(compressedDataUrl.split(",")[1]);
            };

            img.onerror = (error) => reject(error);
          } else {
            var data = event.target.result;
            file = data.split(",");
            // Get the compressed data URL from the canvas
            resolve(file[1]);
          }
        };

        reader.onerror = (error) => reject(error);
      });
    },

    showIdentityDialog() {
      this.isMainDialogVisible = false;
      this.isIdentityDialogVisible = true;
      console.log("if");
    },
    verifyIdentity() {
      console.log("if");
    },
    closeIdentityDialog() {
      console.log("if");
    },
    verifyNow() {
      this.isMainDialogVisible = true;
    },
    closeMainDialog() {
      this.isMainDialogVisible = false;
    },
    showAddressPhoneDialog() {
      this.isAddressPhoneDialogVisible = true;
      this.isMainDialogVisible = false;
    },
    closeAddressPhoneDialog() {
      this.isAddressPhoneDialogVisible = false;
    },
    getFlagURL,
    async getEmailChangeHistory() {
      console.log("i am called");
      this.isLoading = true;
      try {
        const partyId = this.userDetail.partyId;
        let response = await profileAPI.getEmailChangeHistory(partyId);
        this.isLoading = false;
        this.emailChangedHistory = response?.emailChangedHistory;
      } catch (error) {
        this.isLoading = false;
        console.log("====error===", error);
      }
    },
    async getUserDetail() {
      this.$forceUpdate();
      this.profileCardLoading = true;
      this.isLoading = true;
      let userData = {
        accountId: this.userDetail.accountId,
        userId: this.userDetail.userId,
      };
      try {
        let response = await profileAPI.getUserDetail(userData);
        this.profileData = response.userDetail;
        let identityVerificationSubmited =
          !this.profileData?.isIdentityVerified ||
          this.profileData?.isIdentityVerified == "S";
        //this.profileData?.isPhoneVerified == "Y";

        let phoneVerificationFailed =
          !this.profileData?.isPhoneVerified ||
          this.profileData?.isPhoneVerified === "N"; // Strict comparison with 'N'

        console.log("Phone Verification Failed: ", phoneVerificationFailed); // This should output false if it's "Y"

        let identityVerificationFailed =
          !this.profileData?.isIdentityVerified ||
          ["N", "R", "RS"].includes(this.profileData?.isIdentityVerified); // Use an array for multiple checks

        console.log(
          "Identity Verification Failed: ",
          identityVerificationFailed
        );

        console.log("phoneVerificationFailed", phoneVerificationFailed);

        if (!phoneVerificationFailed) {
          this.$set(this, "phoneNumberVerified", true); // This ensures reactivity
          this.$forceUpdate(); // Force Vue to re-render the component
        }

        if (!identityVerificationFailed) {
          this.$set(this, "isIdentityVerified", false);
          this.$forceUpdate();
        }

        if (phoneVerificationFailed || identityVerificationFailed) {
          this.mobileVerificationErrorPopUp = true;

          if (phoneVerificationFailed) {
            this.mobileVerificationError = "Please verify your mobile number!";
            this.$set(this, "phoneNumberVerified", false); // This ensures reactivity
            this.phoneNumberVerified = false; // Change this to false (since failed)
            this.$forceUpdate(); // Force Vue to re-render the component
          } else if (identityVerificationFailed) {
            this.mobileVerificationError = "Please verify your Identity!";
            this.isIdentityVerified = true;
            this.$set(this, "isIdentityVerified", true);
            this.$forceUpdate();
          }
        } else {
          this.mobileVerificationErrorPopUp = false;
        }

        if (identityVerificationSubmited) {
          this.identityVerificationSubmitedPopUp = true;
          this.mobileVerificationError =
            "Documents Submitted - Pending Verification at Admin level!";
        } else {
          this.identityVerificationSubmitedPopUp = false;
        }

        this.profileData.email = response.userDetail.emailAddress.emailAddress;
        localStorage.setItem("checkMail", this.profileData.email);
        this.contactMechIdEmail =
          response.userDetail.emailAddress.contactMechId;
        this.contactMechPurposeTypeIdEmail =
          response.userDetail.emailAddress.contactMechPurposeTypeId;
        this.contactMechIdPincode =
          response.userDetail.postalAddress.contactMechId;
        this.contactMechPurposeTypeIdPincode =
          response.userDetail.postalAddress.contactMechPurposeTypeId;
        this.contactMechIdPhone = response.userDetail.phoneNumber.contactMechId;
        this.contactMechPurposeTypeIdPhone =
          response.userDetail.phoneNumber.contactMechPurposeTypeId;
        this.stateProvinceGeoId =
          response.userDetail.postalAddress.stateProvinceGeoId;
        this.profileData = {
          ...this.profileData,
          ...response.userDetail.postalAddress,
          ...response.userDetail.phoneNumber,
        };
        this.checkImg = response.profileImageData;
        this.userData.profileImageData = `data:image/jpg;base64,${response.profileImageData}`;
        // let countryFilterData = this.checkCountry(
        //   this.profileData.countryGeoId
        // );
        // console.log(
        //   "this.profileData.countryGeoId",
        //   this.profileData.countryGeoId
        // );
        this.profileData.country =
          this.profileData.countryName +
          ": " +
          this.profileData.postalAddress.countryGeoId;
        // countryFilterData.length > 0 ? countryFilterData[0] : "";
        // console.log(
        //   "this.profileData.country",
        //   this.profileData.country,
        //   "condition",
        //   countryFilterData.length > 0 ? countryFilterData[0] : ""
        // );
        await this.getStateList(this.profileData.country);
        this.profileCardLoading = false;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.profileCardLoading = false;
      }
    },

    // checkCountry(countryGeoId) {
    //   return this.countryData.filter((item) => {
    //     let arr = item.split(": ");
    //     if (arr[1] === countryGeoId) {
    //       return item;
    //     }
    //   });
    // },

    async enterClicked(sEmail) {
      var validRegex =
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (sEmail.match(validRegex)) {
        this.test(sEmail);
        return true;
      }
    },

    async test(email) {
      try {
        const partyId = this.userDetail.partyId;
        let response = await countryStateAPI.checkUserNameToUpdateNewEmail(
          email,
          partyId
        );
        if (response.responseMessage == "success") {
          this.valid = true;
          this.showIconEmailVerified = true;
          this.showIconEmailError = false;
          this.showIconEmail = false;
        }
        //this.stateData = response.stateList;
      } catch (error) {
        this.valid = false;
        console.log("error", error);
        this.showIconEmailVerified = false;
        this.showIconEmailError = true;
        this.showIconEmail = false;
        this.snackbar = true;
        this.textError = error.data.messageDetail;
      }
    },

    inputed() {
      this.showIconEmail = true;
      this.showIconEmailVerified = false;
      this.showIconEmailError = false;
    },

    async getCountryList() {
      try {
        let response = await countryStateAPI.getCountryList();
        this.countryData = response.countryList;
      } catch (error) {
        console.log("====error===", error);
      }
    },

    async getStateList(getCountry) {
      try {
        if (getCountry) {
          this.countryGeoId = getCountry.split(": ")[1].trim();
        }
        let response = await countryStateAPI.getStateList(this.countryGeoId);
        this.stateData = response.stateList;
        if (response.stateList == "No States/Provinces exist: _NA_") {
          this.showStateField = false;
          this.stateProvinceGeoId = null;
        } else {
          this.showStateField = true;
        }
        // let stateFilterData = this.checkState(
        //   this.profileData.stateProvinceGeoId
        // );
        // console.log("this.profileData.state---stateFilterData", stateFilterData);

        // this.profileData.state = stateFilterData[0];
        if (this.profileData.state) {
          this.isLoading = false;
        }
        // console.log("this.profileData.state", this.profileData.state);
      } catch (error) {}
    },

    checkState(stateProvinceGeoId) {
      return this.stateData.filter((item) => {
        let arr = item.split(": ");
        if (arr[1] === stateProvinceGeoId) {
          return item;
        }
      });
    },

    getProvinceCode(state) {
      if (state) {
        this.stateProvinceGeoId = state.split(": ")[1].trim();
      }
      // console.log("this.stateProvinceGeoId", this.stateProvinceGeoId);
    },

    async sendVerificationOtp() {
      this.isLoading = true;
      let sendVerificationPayload = {
        countryCode: this.profileData?.countryCode,
        contactNumber: this.profileData?.contactNumber,
      };

      try {
        let response = await profileAPI.sendVerificationCode(
          sendVerificationPayload
        );
        this.isLoading = false;

        setTimeout(() => {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response?.messageDetail,
            color: "success",
          });
        }, 3000);
      } catch (err) {
        setTimeout(() => {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: err.data.messageDetail,
            color: "error",
          });
        }, 3000);
        this.isLoading = false;
      }
    },

    async submitUserForm() {
      // this.isLoading = true;
      let documentInfoId;
      this.userDocumentInfo.documentInfoType = this.radioGroup;
      var submitForm = false;

      if (this.submitForm) {
        submitForm = true;
      } else if (this.uploadedFiles.length != 0) {
        submitForm = true;
      }

      var getData = {};

      if (this.$refs.form.validate()) {
        if (submitForm) {
          delete this.userDocumentInfo.document1;
          delete this.userDocumentInfo.document2;
          this.userDocumentInfo.uploadedFiles = this.uploadedFiles;
          this.userDocumentInfo.selectedDocType = this.radioGroup;
          this.userDocumentInfo.geoId = this.geoIDSelected;
          this.userDocumentInfo.partyId = this.userDetail.partyId;
          this.userDocumentInfo.documentType = "VERIFICATION";

          if (this.userDocumentInfo.dateOfBirth) {
            getData = {
              dateOfBirth: this.formatDate(this.userDocumentInfo.dateOfBirth),
            };
          }
          this.submitLoader = true;
          try {
            let response = await shoppingCartAPI.uploadDocumentInfo({
              ...this.userDocumentInfo,
              ...getData,
            });

            // this.document.documentInfoId = response.outMap.documentInfoId;
            this.document.documentInfoId = "";
            if (
              response.outMap.documentInfoId != null ||
              response.outMap.documentInfoId != undefined
            ) {
              this.document.documentInfoId = response.outMap.documentInfoId;
              documentInfoId = this.document.documentInfoId;
            }
            this.document.geoId = response.outMap.geoId;
            this.isIdentityDialogVisible = false;
            this.submitLoader = false;
            submitForm = false;
            this.submitForm = false;
            this.geoIDSelected = this.document.geoId || "ARG";
            //this.getSelectedDocumentInfoData(documentInfoId);
            this.userFormDialog = false;
            if (!this.selectedIdentity) {
              this.outList = [];
              this.uploadedFiles = [];
            }

            // if (!this.phoneNumberVerified) {
            //   this.isAddressPhoneDialogVisible = true;
            // }
            this.$root.$emit("PROFILE_UPDATED"); // Emit event here after profile is updated

            this.$root.$emit("SHOW_SNACKBAR", {
              text: response.responseMessage,
              color: "success",
            });
            this.isLoading = false;
            this.getUserDetail();
          } catch (error) {
            console.log("Error", error);
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "something wrong",
              color: "error",
            });
            this.submitLoader = false;
          }
        } else {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "Please upload documents!",
            color: "error",
          });
          this.submitLoader = false;
        }
      }
    },

    formatDate(SelectedDate) {
      var date = new Date();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      return `${SelectedDate} ${hours}:${minutes}:${seconds}`;
    },

    async verifyVerificationOtp() {
      let verifyCodePayload = {
        countryCode: this.profileData?.countryCode,
        contactNumber: this.profileData?.contactNumber,
        code: this.verificationCode,
      };

      try {
        let response = await profileAPI.verificationCheck(verifyCodePayload);
        this.isLoading = false;
        this.getUserDetail();
        this.$forceUpdate();

        this.isAddressPhoneDialogVisible = false;
        if (this.isIdentityVerified) {
          this.isIdentityDialogVisible = true;
        }
        //this.mobileVerificationError = false;
        setTimeout(() => {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response?.messageDetail,
            color: "success",
          });
        }, 3000);
      } catch (err) {
        setTimeout(() => {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: err.data.messageDetail,
            color: "error",
          });
        }, 3000);
        this.isLoading = false;
      }
    },

    async updateProfile() {
      this.isLoading = true;
      let authToken = localStorage.getItem("authToken");
      let profileDataSend = {
        updateData: {
          postalAddress: {
            address1: this.profileData.address1,
            address2: this.profileData.address2,
            city: this.profileData.city,
            contactMechId: this.contactMechIdPincode,
            contactMechPurposeTypeId: this.contactMechPurposeTypeIdPincode,
            countryGeoId: this.countryGeoId,
            postalCode: this.profileData.postalCode,
            stateProvinceGeoId: this.stateProvinceGeoId,
          },
          phoneNumber: {
            contactMechId: this.contactMechIdPhone,
            contactMechPurposeTypeId: this.contactMechPurposeTypeIdPhone,
            contactNumber: this.profileData.contactNumber,
            countryCode: this.profileData.countryCode,
          },
          emailAddress: {
            contactMechId: this.contactMechIdEmail,
            contactMechPurposeTypeId: this.contactMechPurposeTypeIdEmail,
            emailAddress: this.profileData.email,
          },
          firstName: this.profileData.firstName,
          lastName: this.profileData.lastName,
          companyName: this.profileData.companyName,
          vatNumber: this.profileData.vatNumber,
          taxNumber: this.profileData.taxNumber,
          password: this.getPassword.replace(/"([^"]+(?="))"/g, "$1"),
        },
        userId: this.userDetail.userId,
        accountId: this.userDetail.accountId,
        authToken: authToken,
      };
      try {
        let checkMail = localStorage.getItem("checkMail");
        if (this.profileData.email != checkMail) {
          this.showEmailChangeDialog = true;
          this.isLoading = false;
        } else {
          let response = await profileAPI.updateProfile(profileDataSend);
          this.isLoading = false;
          this.getUserDetail();

          this.$root.$emit("CHANGE_PROFILE_DATA", {
            name: `${this.profileData.firstName} ${this.profileData.lastName}`,
          });
          setTimeout(() => {
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "Successfully profile updated",
              color: "success",
            });
          }, 3000);
        }
      } catch (err) {
        setTimeout(() => {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: err.data.messageDetail,
            color: "error",
          });
        }, 3000);
        this.isLoading = false;
      }
    },

    cancelEmailChangeDialogPopUp() {
      this.showEmailChangeDialog = false;
    },

    async createEmailVerification() {
      this.showEmailChangeDialog = false;
      this.isLoading = true;
      let authToken = localStorage.getItem("authToken");
      let profileDataSend = {
        updateData: {
          postalAddress: {
            address1: this.profileData.address1,
            address2: this.profileData.address2,
            city: this.profileData.city,
            contactMechId: this.contactMechIdPincode,
            contactMechPurposeTypeId: this.contactMechPurposeTypeIdPincode,
            countryGeoId: this.countryGeoId,
            postalCode: this.profileData.postalCode,
            stateProvinceGeoId: this.stateProvinceGeoId,
          },
          phoneNumber: {
            contactMechId: this.contactMechIdPhone,
            contactMechPurposeTypeId: this.contactMechPurposeTypeIdPhone,
            contactNumber: this.profileData.contactNumber,
            countryCode: this.profileData.countryCode,
          },
          emailAddress: {
            contactMechId: this.contactMechIdEmail,
            contactMechPurposeTypeId: this.contactMechPurposeTypeIdEmail,
            emailAddress: this.profileData.email,
          },
          firstName: this.profileData.firstName,
          lastName: this.profileData.lastName,
          companyName: this.profileData.companyName,
          vatNumber: this.profileData.vatNumber,
          taxNumber: this.profileData.taxNumber,
          password: this.getPassword.replace(/"([^"]+(?="))"/g, "$1"),
        },
        userId: this.userDetail.userId,
        accountId: this.userDetail.accountId,
        authToken: authToken,
      };
      try {
        let checkMail = localStorage.getItem("checkMail");

        let response = await profileAPI.updateProfile(profileDataSend);
        let response2;
        if (response && response.responseMessage == "success") {
          const emailVerificationDataToSend = {
            emailAddress: this.profileData.email,
            partyId: this.userDetail.partyId,
            isVerified: "N",
          };
          response2 = await profileAPI.createEmailVerification(
            emailVerificationDataToSend
          );
        }
        this.getUserDetail();

        this.$root.$emit("CHANGE_PROFILE_DATA", {
          name: `${this.profileData.firstName} ${this.profileData.lastName}`,
        });
        this.isLoading = false;
        setTimeout(() => {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response2?.messageDetail,
            color: "success",
          });
        }, 3000);
      } catch (err) {
        this.snackbar = true;
        this.textError = err.data.messageDetail;
        setTimeout(() => {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: err.data.messageDetail,
            color: "error",
          });
        }, 3000);
        this.isLoading = false;
      }

      //this.isLoading = false;
    },

    checkValidCompanyName() {
      if (this.profileData.companyName.length != 0) {
        this.companyNameRules = [
          (v) => (v && v.length <= 40) || "this value is required",
        ];
      } else {
        this.companyNameRules = [];
      }
    },
    checkValidVatNumber() {
      if (this.profileData.vatNumber.length != 0) {
        this.vatNumberRules = [
          (v) => (v && v.length <= 12) || "this value is required",
        ];
      } else {
        this.vatNumberRules = [];
      }
    },
    checkValidTaxNumber() {
      if (this.profileData.taxNumber.length != 0) {
        this.taxNumberRules = [
          (v) => (v && v.length <= 16) || "this value is required",
        ];
      } else {
        this.taxNumberRules = [];
      }
    },
    checkValidPinCode(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
      if (this.signupData.pinCode.length != 0) {
        this.pincodeRule = [
          (v) => (v && v.length <= 9) || "Maximum 9 characters allowed",
        ];
      } else {
        this.taxNumberRules = [];
      }
    },
    checkCityCode(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
      if (this.signupData.city.length != 0) {
        this.cityRule = [
          (v) => (v && v.length <= 15) || "Maximum 15 characters allowed",
        ];
      } else {
        this.taxNumberRules = [];
      }
    },

    async getTimezoeData() {
      let userData = {
        accountId: this.userDetail.accountId,
        authToken: localStorage.getItem("authToken"),
      };
      try {
        let response = await profileAPI.getAccountsDetails(userData);
        if (response.accountdetails.timezone) {
          this.selectedTimezone = response.accountdetails.timezone;
          this.getTimezoneData = response.accountdetails;
        }
      } catch (error) {
        console.log("====error===", error);
      }
    },
    async updateTimezone() {
      // update timezone data
      let updateTimeZoneData = {
        accountId: this.userDetail.accountId,
        editData: {
          data: this.getTimezoneData,
        },
      };

      if (this.selectedTimezone) {
        updateTimeZoneData.editData.data.timezone = this.selectedTimezone;
      }

      this.isLoading = true;

      try {
        let response = await profileAPI.updateTimezone(updateTimeZoneData);
        if (response) {
          this.getTimezoeData();
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "Successfully Timezone updated",
            color: "success",
          });
          this.isLoading = false;
        }
      } catch (error) {
        console.log("====error===", error);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.custom-file-input .v-input__control {
  height: 56px; /* Adjust the height as needed */
  color: black; /* Ensure the text is darker */
  font-weight: bold; /* Make the text bold */
}

.custom-file-input .v-label {
  color: black !important; /* Ensure the label text is darker */
}

.custom-file-input .v-input__control .v-label::before {
  content: "Drag and drop your files here or click to browse";
}

.v-file-input--outlined .v-input__control {
  border: 2px dashed #000; /* Match the border style */
}

.document-upload p {
  margin: 0;
  font-weight: bold;
}

.document-upload ul {
  margin: 0;
  padding-left: 20px;
  margin-bottom: 10px;
}

.v-card-actions {
  padding-right: 16px;
  padding-bottom: 16px;
}
.headline {
  font-size: 1.5rem;
  font-weight: bold;
}
.section-title {
  font-weight: bold;
  margin-top: 16px;
}
.section-description {
  margin-bottom: 16px;
  font-size: 0.875rem;
}
.verification-help {
  color: black;
  font-weight: bold;
}

.rounded-card {
  border-radius: 10px;
}

.update-button {
  padding-left: 40%;
  padding-top: inherit;
}

.error-message {
  color: red;
}

.verify-button {
  background-color: pink;
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
}

.verification-steps {
  margin-top: 20px;
}

.step {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.step:hover {
  background-color: #f5f5f5;
}

.step.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.step-number {
  margin-right: 10px;
}

.headline {
  padding-bottom: 0;
}

.v-card-text p {
  margin-bottom: 20px;
}

.v-card-actions {
  margin-top: 20px;
}

.modal-font-weight {
  font-weight: 450;
  color: #000;
}
</style>
